@import "src/themes/mojito/styles/index.scss";
.dropdown {
  position: relative;

  .panel {
    display: none;

    &--visible {
      display: block;
    }
  }
}
