@import "src/themes/mojito/styles/index.scss";
.feedback-info {
  background: #f2f2f2;
  padding: 8px;

  &--title p {
    font-weight: 700;
    font-size: 14px !important;
    margin-bottom: 4px;
  }
  &--description p {
    font-size: 12px !important;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
  }
}

.feedback-single {
  //min-height: 319px;
  // min-height: 402px;
  .feedback-categories {
    margin-top: space(1);
    display: flex;
    height: 100%;
    justify-content: center;

    .feedback-category {
      //   height: 230px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &--name {
        font-weight: 700;
        font-size: 20px;
      }
      &--rating {
        margin: space(1) 0;
      }
      &--input {
        display: flex;
        flex-direction: column;
        text-align: left !important;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 4px;
        width: 100% !important;
        font-family: getvar($typography, "font-family-body");

        label.feedback-label {
          text-align: left !important;
          color: getvar($colors, "neutral", "800");
        }
        textarea,
        textarea::placeholder {
          font-family: getvar($typography, "font-family-body") !important;
        }

        .feedback-textarea {
          border: 1px solid getvar($colors, "neutral", "300") !important;
          border-radius: 4px;
          margin-top: 4px !important;
          // width: 84% !important;
          height: space(4.25);
          padding: 8px 16px;

          &--counter {
            font-weight: 400;
            font-size: space(0.625);
            color: getvar($colors, "neutral", "500");
            margin-top: 5px;
            text-align: right;
          }
        }
        .feedback-textarea:active,
        .feedback-textarea:focus,
        .feedback-textarea:focus-visible {
          border: 1px solid getvar($colors, "neutral", "300") !important;
        }
      }
    }
  }
}

.feedback-single.hasMultipleCategories {
  // height: 260px;
  word-break: break-word;
}

.feedback-multiple {
  .feedback-info {
    padding: 4px;
  }

  .feedback-category {
    &--list {
      // height: 220px;
      margin-top: space(1) !important;
      overflow-y: auto;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 16px;
    }

    &--item {
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      padding: 5px 8px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item-name {
        width: 65%;
        p {
          margin-bottom: 0;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
        }
      }

      .chevron::before {
        border-style: solid;
        border-color: #373a36;
        border-width: space(0.125) space(0.125) 0 0;
        content: "";
        display: inline-block;
        height: 5px;
        left: -2px;
        position: relative;
        top: 8px;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 5px;
        transform: rotate(45deg);
      }
    }
    &--item:hover {
      cursor: pointer;
    }
  }
}

.feedback-addInfo {
  margin-top: space(1);
  height: 285px;
  &--header {
    .feedback-addInfo-title p {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #404040;
      margin-bottom: 4px;
    }
    .feedback-addInfo-description p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }
  &--form {
    margin-top: space(1);
    .Input__element {
      padding: space(0.5);
      margin-bottom: space(0.5);
    }
    .Input__label {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 4px;
    }
  }
}

.feedback-thankyou {
  height: 253px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  svg {
    margin-bottom: 24px;
    width: 72px;
    height: 72px;
  }
  &--title {
    font-weight: 700;
    font-size: 20px;
    line-height: 16px;
    margin-bottom: 0;
  }
  &--subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.feedback-navigation {
  line-height: 14px;
  button {
    justify-content: flex-end !important;
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: space(1);
    }
    svg {
      height: space(1);
      width: space(1);
    }
  }
}
